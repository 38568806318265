import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

import images from '../images/images/education2.png';

function PoUp() {

  useEffect( () => {
    let search = window.location.search;
    let params = new URLSearchParams( search );
    let message = params.get( 'message' );
    Swal.fire( {
      imageUrl: images,
      imageHeight: 151,
      imageWidth: 125,
      title: '',
      confirmButtonText: 'Okay',
      text: message,
      type: 'warning'
    } ).then( () => {
      location.href = '/';
    } );
  }, [] );

  return(
    <>
    </>
  );
}

export default PoUp;
